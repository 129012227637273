/******************************************************/
//Tenant
/******************************************************/
export const mutation_update_tenant = `
mutation updateTenant($tenantId: String!, $name: String!, $identifier: String!, $vatNumber: String!, $addrStreetName: String!, $addrStreetNumber: String!, $addrCity: String!, $addrProvince: String!, $addrZipCode: String!, $addrCountry: String!, $addrFull: String!, $classifier: String!) {
    update_tenant(where: {TenantId: {_eq: $tenantId}}, _set: {Name: $name, Identifier: $identifier, VatNumber: $vatNumber, AddrStreetName: $addrStreetName, AddrStreetNumber: $addrStreetNumber, AddrCity: $addrCity, AddrProvince: $addrProvince, AddrZipCode: $addrZipCode, AddrCountry: $addrCountry, AddrFull: $addrFull, Classifier: $classifier}) {
        returning {
          TenantId
          OwnerId
          Name
          Identifier
          IsReadOnly
          IsDisabled
          IsDeleted
          StatusDate
          VatNumber
          AddrStreetName
          AddrStreetNumber
          AddrCity
          AddrProvince
          AddrZipCode
          AddrCountry
          AddrFull
          Classifier
        }
    }
  }  
`;

/******************************************************/
//Azienda
/******************************************************/
export const mutation_add_azienda = `
mutation insertAzienda($tenantId: String!, $denominazione: String!, $cf: String!, $pIva: String!, $addrPaeseId: Int, $addrProvinciaId: Int, $addrProvincia: String, $addrComuneId: Int, $addrComune: String, $addrIndirizzo: String, $addrN: String, $addrCap: String, $meteringSlotId: Int) {
    insert_azienda_one(object: {TenantId: $tenantId, Denominazione: $denominazione, Cf: $cf, PIva: $pIva, AddrPaeseId: $addrPaeseId, AddrProvinciaId: $addrProvinciaId, AddrProvincia: $addrProvincia, AddrComuneId: $addrComuneId, AddrComune: $addrComune, AddrIndirizzo: $addrIndirizzo, AddrN: $addrN, AddrCap: $addrCap, MeteringSlotId: $meteringSlotId}) {
    Id
    Denominazione
    Cf
    PIva
    AddrPaeseId
    AddrProvinciaId
    AddrProvincia
    AddrComuneId
    AddrComune
    AddrIndirizzo
    AddrN
    AddrCap
    CreatedOn
    ModifiedOn
    CreatedBy
    ModifiedBy
    MeteringSlotId
  }
}
`;
export const mutation_update_azienda = `
mutation updateAzienda($id: Int!, $denominazione: String!, $cf: String!, $pIva: String!, $addrPaeseId: Int!, $addrProvinciaId: Int!, $addrProvincia: String!, $addrComuneId: Int!, $addrComune: String!, $addrIndirizzo: String!, $addrN: String!, $addrCap: String!, $meteringSlotId: Int) {
    update_azienda(where: {Id: {_eq: $id}}, _set: {Denominazione: $denominazione, Cf: $cf, PIva: $pIva, AddrPaeseId: $addrPaeseId, AddrProvinciaId: $addrProvinciaId, AddrProvincia: $addrProvincia, AddrComuneId: $addrComuneId, AddrComune: $addrComune, AddrIndirizzo: $addrIndirizzo, AddrN: $addrN, AddrCap: $addrCap, ModifiedOn: "now()", MeteringSlotId: $meteringSlotId}) {
        returning {
          Id
          TenantId
          Denominazione
          Cf
          PIva
          AddrPaeseId
          AddrProvinciaId
          AddrProvincia
          AddrComuneId
          AddrComune
          AddrIndirizzo
          AddrN
          AddrCap
          CreatedOn
          ModifiedOn
          CreatedBy
          ModifiedBy
          MeteringSlotId
        }
    }
  }  
`;
export const mutation_delete_azienda = `
mutation deleteAzienda($tenantId: String!, $id: Int!) {
    delete_azienda(where: {Id: {_eq: $id}, TenantId: {_eq: $tenantId}}) {
        affected_rows
    }
}
`;

/******************************************************/
//Tabelle di base
/******************************************************/
//base_mezzo_pagamento
export const mutation_add_base_mezzo_pagamento = `
mutation insertBaseMezzoPagamento($tenantId: String!, $key: String!, $value: String!, $order: Int!) {
    insert_base_mezzo_pagamento_one(object: {TenantId: $tenantId, Key: $key, Value: $value, Order: $order, IsSystem: false}) {
        Id
        TenantId
        Key
        Value
        Order
        IsSystem
      }    
}
`;
export const mutation_update_base_mezzo_pagamento = `
mutation updateBaseMezzoPagamento($id: Int!, $tenantId: String!, $value: String!) {
    update_base_mezzo_pagamento(where: {Id: {_eq: $id}, TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}, _set: {Value: $value}) {
        returning {
            Id
            Key
            Value
            Order
            IsSystem
          }
    }
  }  
`;
export const mutation_delete_base_mezzo_pagamento = `
mutation deleteBaseMezzoPagamento($tenantId: String!, $id: Int!) {
    delete_base_mezzo_pagamento(where: {Id: {_eq: $id}, IsSystem: {_eq: false}, TenantId: {_eq: $tenantId}}) {
        affected_rows
    }
}
`;

//base_natura_prestazione
export const mutation_add_base_natura_prestazione = `
mutation insertBaseNaturaPrestazione($tenantId: String!, $key: String!, $value: String!, $order: Int!) {
    insert_base_natura_prestazione_one(object: {TenantId: $tenantId, Key: $key, Value: $value, Order: $order, IsSystem: false}) {
        Id
        TenantId
        Key
        Value
        Order
        IsSystem
      }    
}
`;
export const mutation_update_base_natura_prestazione = `
mutation updateBaseNaturaPrestazione($id: Int!, $tenantId: String!, $value: String!) {
    update_base_natura_prestazione(where: {Id: {_eq: $id}, TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}, _set: {Value: $value}) {
        returning {
            Id
            Key
            Value
            Order
            IsSystem
          }
    }
  }  
`;
export const mutation_delete_base_natura_prestazione = `
mutation deleteBaseNaturaPrestazione($tenantId: String!, $id: Int!) {
    delete_base_natura_prestazione(where: {Id: {_eq: $id}, IsSystem: {_eq: false}, TenantId: {_eq: $tenantId}}) {
        affected_rows
    }
}
`;

//base_natura_rapporto
export const mutation_add_base_natura_rapporto = `
mutation insertBaseNaturaRapporto($tenantId: String!, $key: String!, $value: String!, $order: Int!) {
    insert_base_natura_rapporto_one(object: {TenantId: $tenantId, Key: $key, Value: $value, Order: $order, IsSystem: false}) {
        Id
        TenantId
        Key
        Value
        Order
        IsSystem
      }    
}
`;
export const mutation_update_base_natura_rapporto = `
mutation updateBaseNaturaRapporto($id: Int!, $tenantId: String!, $value: String!) {
    update_base_natura_rapporto(where: {Id: {_eq: $id}, TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}, _set: {Value: $value}) {
        returning {
            Id
            Key
            Value
            Order
            IsSystem
          }
    }
  }  
`;
export const mutation_delete_base_natura_rapporto = `
mutation deleteBaseNaturaRapporto($tenantId: String!, $id: Int!) {
    delete_base_natura_rapporto(where: {Id: {_eq: $id}, IsSystem: {_eq: false}, TenantId: {_eq: $tenantId}}) {
        affected_rows
    }
}
`;

//base_origine_fondi
export const mutation_add_base_origine_fondi = `
mutation insertBaseOrigineFondi($tenantId: String!, $key: String!, $value: String!, $order: Int!) {
    insert_base_origine_fondi_one(object: {TenantId: $tenantId, Key: $key, Value: $value, Order: $order, IsSystem: false}) {
        Id
        TenantId
        Key
        Value
        Order
        IsSystem
      }    
}
`;
export const mutation_update_base_origine_fondi = `
mutation updateBaseOrigineFondi($id: Int!, $tenantId: String!, $value: String!) {
    update_base_origine_fondi(where: {Id: {_eq: $id}, TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}, _set: {Value: $value}) {
        returning {
            Id
            Key
            Value
            Order
            IsSystem
          }
    }
  }  
`;
export const mutation_delete_base_origine_fondi = `
mutation deleteBaseOrigineFondi($tenantId: String!, $id: Int!) {
    delete_base_origine_fondi(where: {Id: {_eq: $id}, IsSystem: {_eq: false}, TenantId: {_eq: $tenantId}}) {
        affected_rows
    }
}
`;

//base_scopo_prestazione
export const mutation_add_base_scopo_prestazione = `
mutation insertBaseScopoPrestazione($tenantId: String!, $key: String!, $value: String!, $order: Int!) {
    insert_base_scopo_prestazione_one(object: {TenantId: $tenantId, Key: $key, Value: $value, Order: $order, IsSystem: false}) {
        Id
        TenantId
        Key
        Value
        Order
        IsSystem
      }    
}
`;
export const mutation_update_base_scopo_prestazione = `
mutation updateBaseScopoPrestazione($id: Int!, $tenantId: String!, $value: String!) {
    update_base_scopo_prestazione(where: {Id: {_eq: $id}, TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}, _set: {Value: $value}) {
        returning {
            Id
            Key
            Value
            Order
            IsSystem
          }
    }
  }  
`;
export const mutation_delete_base_scopo_prestazione = `
mutation deleteBaseScopoPrestazione($tenantId: String!, $id: Int!) {
    delete_base_scopo_prestazione(where: {Id: {_eq: $id}, IsSystem: {_eq: false}, TenantId: {_eq: $tenantId}}) {
        affected_rows
    }
}
`;

//base_tipo_documento
export const mutation_add_base_tipo_documento = `
mutation insertBaseTipoDocumento($tenantId: String!, $key: String!, $value: String!, $order: Int!, $documentTypeId:Int!, $validityMonths : Int, $offsetDays : Int, $nextBirthDay : Boolean, $personType : String) {
    insert_base_tipo_documento_one(object: {TenantId: $tenantId, Key: $key, Value: $value, Order: $order, IsSystem: false, DocumentTypeId : $documentTypeId, ValidityMonths : $validityMonths, ValidityOffsetDays : $offsetDays, NextBirthDay : $nextBirthDay, PersonType: $personType}) {        
        Id
        TenantId
        Key
        Value
        Order
        IsSystem
        DocumentTypeId
        PersonType
        ValidityMonths
        ValidityOffsetDays
        NextBirthDay
      }    
}
`;
export const mutation_update_base_tipo_documento = `
mutation updateBaseTipoDocumento($id: Int!, $tenantId: String!, $value: String!, $documentTypeId : Int!, $validityMonths : Int, $validityOffsetDays : Int, $nextBirthDay : Boolean) {
    update_base_tipo_documento(where: {Id: {_eq: $id}, TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}, _set: {Value: $value, DocumentTypeId : $documentTypeId, ValidityMonths : $validityMonths, ValidityOffsetDays : $validityOffsetDays, NextBirthDay : $nextBirthDay}) {
        returning {
            Id
            Key
            Value
            Order
            IsSystem
          }
    }
  }
`;
export const mutation_delete_base_tipo_documento = `
mutation deleteBaseTipoDocumento($tenantId: String!, $id: Int!) {
    delete_base_tipo_documento(where: {Id: {_eq: $id}, IsSystem: {_eq: false}, TenantId: {_eq: $tenantId}}) {
        affected_rows
    }
}
`;

//base_tipo_identificazione
export const mutation_add_base_tipo_identificazione = `
mutation insertBaseTipoIdentificazione($tenantId: String!, $key: String!, $value: String!, $order: Int!) {
    insert_base_tipo_identificazione_one(object: {TenantId: $tenantId, Key: $key, Value: $value, Order: $order, IsSystem: false}) {
        Id
        TenantId
        Key
        Value
        Order
        IsSystem
      }    
}
`;
export const mutation_update_base_tipo_identificazione = `
mutation updateBaseTipoIdentificazione($id: Int!, $tenantId: String!, $value: String!) {
    update_base_tipo_identificazione(where: {Id: {_eq: $id}, TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}, _set: {Value: $value}) {
        returning {
            Id
            Key
            Value
            Order
            IsSystem
          }
    }
  }
`;
export const mutation_delete_base_tipo_identificazione = `
mutation deleteBaseTipoIdentificazione($tenantId: String!, $id: Int!) {
    delete_base_tipo_identificazione(where: {Id: {_eq: $id}, IsSystem: {_eq: false}, TenantId: {_eq: $tenantId}}) {
        affected_rows
    }
}
`;

//base_tipo_personalita
export const mutation_add_base_tipo_personalita = `
mutation insertBaseTipoPersonalita($tenantId: String!, $key: String!, $value: String!, $order: Int!) {
    insert_base_tipo_personalita_one(object: {TenantId: $tenantId, Key: $key, Value: $value, Order: $order, IsSystem: false}) {
        Id
        TenantId
        Key
        Value
        Order
        IsSystem
      }    
}
`;
export const mutation_update_base_tipo_personalita = `
mutation updateBaseTipoPersonalita($id: Int!, $tenantId: String!, $value: String!) {
    update_base_tipo_personalita(where: {Id: {_eq: $id}, TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}, _set: {Value: $value}) {
        returning {
            Id
            Key
            Value
            Order
            IsSystem
          }
    }
  }
`;
export const mutation_delete_base_tipo_personalita = `
mutation deleteBaseTipoPersonalita($tenantId: String!, $id: Int!) {
    delete_base_tipo_personalita(where: {Id: {_eq: $id}, IsSystem: {_eq: false}, TenantId: {_eq: $tenantId}}) {
        affected_rows
    }
}
`;

//base_genere
export const mutation_add_base_genere = `
mutation insertBaseGenere($tenantId: String!, $key: String!, $value: String!, $order: Int!) {
    insert_base_genere_one(object: {TenantId: $tenantId, Key: $key, Value: $value, Order: $order, IsSystem: false}) {
        Id
        TenantId
        Key
        Value
        Order
        IsSystem
      }  
}
`;
export const mutation_update_base_genere = `
mutation updateBaseGenere($id: Int!, $tenantId: String!, $value: String!) {
    update_base_genere(where: {Id: {_eq: $id}, TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}, _set: {Value: $value}) {
        returning {
            Id
            Key
            Value
            Order
            IsSystem
          }
    }
  }
`;
export const mutation_delete_base_genere = `
mutation deleteBaseGenere($tenantId: String!, $id: Int!) {
    delete_base_genere(where: {Id: {_eq: $id}, IsSystem: {_eq: false}, TenantId: {_eq: $tenantId}}) {
        affected_rows
    }
}
`;

/******************************************************/
//Fascicolo
/******************************************************/
export const mutation_add_fascicolo = `
mutation addFascicolo($tenantId: String!, $aziendaId: Int!, $name: String!, $date: timestamp!, $isPrivate: Boolean!) {
  insert_fascicolo_one(object: {TenantId: $tenantId, AziendaId: $aziendaId, Name: $name, Date: $date, IsPrivate: $isPrivate}) {
        Id
        TenantId
        AziendaId
        Name
        Date
        IsPrivate
        CreatedOn
        ModifiedOn
        CreatedBy
        ModifiedBy
      }
  }
`;
export const mutation_update_fascicolo = `
mutation updateFascicolo($id: Int!, $name: String!, $date: timestamp!, $isPrivate: Boolean!) {
  update_fascicolo(where: {Id: {_eq: $id}}, _set: {Name: $name, Date: $date, IsPrivate: $isPrivate, ModifiedOn: "now()"}) {
      returning {
        Id
        TenantId
        Name
        Date
        IsPrivate
        CreatedOn
        ModifiedOn
        CreatedBy
        ModifiedBy
      }
    }
  }
`;
export const mutation_delete_fascicolo = `
mutation deleteFascicolo($tenantId: String!, $aziendaId: Int!, $id: Int!) {
    delete_fascicolo(where: {Id: {_eq: $id}, TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}}) {
        affected_rows
    }
}
`;

/******************************************************/
//Fascicolo - Anagrafiche
/******************************************************/
export const mutation_add_anagrafica = `
mutation addAnagrafica($tenantId: String!, $aziendaId: Int!, $parentId: Int!, $isPg: Boolean!, $cf: String!, $nome: String, $cognome: String, $nascitaLuogo: String, $nascitaData: timestamp, $professione: String, $denominazione: String, $pIva: String, $reaComune: String, $reaN: String, $addrPaeseId: Int, $addrProvinciaId: Int, $addrProvincia: String, $addrComuneId: Int, $addrComune: String, $addrIndirizzo: String!, $addrN: String, $addrCap: String!, $addr2PaeseId: Int, $addr2ProvinciaId: Int, $addr2Provincia: String, $addr2ComuneId: Int, $addr2Comune: String, $addr2Indirizzo: String, $addr2N: String, $addr2Cap: String, $isPep: Boolean!, $isNotPepMoreThanYear: Boolean!, $pepCarica: String, $pepRuolo : String, $versionN: Int!, $versionDate: timestamp, $isDeleted: Boolean!, $genereId: Int, $importDesc: String, $workspaceId: String, $AmlId : String, $Office1Id : String , $Office2Id : String) {
  insert_anagrafica_one(object: {TenantId: $tenantId, AziendaId: $aziendaId, ParentId: $parentId, IsPg: $isPg, Cf: $cf, Nome: $nome, Cognome: $cognome, NascitaLuogo: $nascitaLuogo, NascitaData: $nascitaData, Professione: $professione, Denominazione: $denominazione, PIva: $pIva, ReaComune: $reaComune, ReaN: $reaN, AddrPaeseId: $addrPaeseId, AddrProvinciaId: $addrProvinciaId, AddrProvincia: $addrProvincia, AddrComuneId: $addrComuneId, AddrComune: $addrComune, AddrIndirizzo: $addrIndirizzo, AddrN: $addrN, AddrCap: $addrCap, Addr2PaeseId: $addr2PaeseId, Addr2ProvinciaId: $addr2ProvinciaId, Addr2Provincia: $addr2Provincia, Addr2ComuneId: $addr2ComuneId, Addr2Comune: $addr2Comune, Addr2Indirizzo: $addr2Indirizzo, Addr2N: $addr2N, Addr2Cap: $addr2Cap, IsPep: $isPep, IsNotPepMoreThanYear: $isNotPepMoreThanYear, PepCarica: $pepCarica, PepRuolo: $pepRuolo, VersionN: $versionN, VersionDate: $versionDate, IsDeleted: $isDeleted, GenereId: $genereId, ImportDesc: $importDesc, WorkspaceId: $workspaceId, AmlId : $AmlId, Office1Id : $Office1Id, Office2Id : $Office2Id }) {
      Id
      ParentId
      IsPg
      Cf
      Nome
      Cognome
      GenereId
      NascitaLuogo
      NascitaData
      Professione
      Denominazione
      PIva
      ReaComune
      ReaN
      AddrPaeseId
      AddrProvinciaId
      AddrProvincia
      AddrComuneId
      AddrComune
      AddrIndirizzo
      AddrN
      AddrCap
      Addr2PaeseId
      Addr2ProvinciaId
      Addr2Provincia
      Addr2ComuneId
      Addr2Comune
      Addr2Indirizzo
      Addr2N
      Addr2Cap
      IsPep
      IsNotPepMoreThanYear
      PepCarica
      CreatedOn
      VersionN
      VersionDate
      IsDeleted
      CreatedBy
      ModifiedBy
      ModifiedOn
      WorkspaceId
      AmlId
      Office1Id
      Office2Id
      anagrafica_fascicolos {
        Id
        fascicolo {
          Id
          Name
          Date
          CreatedBy
          ModifiedBy
        }
        base_tipo_anagrafica {
          Id
          Key
          Value
        }
        TipoPartId
        TipoPartDesc
      }
      anagrafica_identificaziones {
        Id
        DocTipoId
        DocN
        DocRilDa
        DocRilData
        DocScad
        ApNome
        ApCf
        IdentifiedByUser
        IdentifiedOn
        CreatedOn
        ModifiedOn
        CreatedBy
        ModifiedBy
        base_tipo_identificazione {
          Id
          Key
          Value
        }
      }
      anagrafica_bancadatis {
        Id
        Repository
        Infos
        CreatedOn
      }
    }
  }
`;
export const mutation_update_anagrafica = `
mutation updateAnagrafica($id: Int!, $tenantId: String!, $aziendaId: Int!, $parentId: Int!, $isPg: Boolean!, $cf: String!, $nome: String, $cognome: String, $nascitaLuogo: String, $nascitaData: timestamp, $professione: String, $denominazione: String, $pIva: String, $reaComune: String, $reaN: String, $addrPaeseId: Int!, $addrProvinciaId: Int, $addrProvincia: String, $addrComuneId: Int, $addrComune: String, $addrIndirizzo: String!, $addrN: String, $addrCap: String!, $addr2PaeseId: Int, $addr2ProvinciaId: Int, $addr2Provincia: String, $addr2ComuneId: Int, $addr2Comune: String, $addr2Indirizzo: String, $addr2N: String, $addr2Cap: String, $isPep: Boolean!, $isNotPepMoreThanYear: Boolean!, $pepCarica: String, $pepRuolo : String, $versionN: Int!, $versionDate: timestamp, $isDeleted: Boolean!, $genereId: Int, $workspaceId: String, $AmlId : String, $Office1Id : String, $Office2Id : String ) {
  update_anagrafica(where: {Id: {_eq: $id}, TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}}, _set: {ParentId: $parentId, IsPg: $isPg, Cf: $cf, Nome: $nome, Cognome: $cognome, NascitaLuogo: $nascitaLuogo, NascitaData: $nascitaData, Professione: $professione, Denominazione: $denominazione, PIva: $pIva, ReaComune: $reaComune, ReaN: $reaN, AddrPaeseId: $addrPaeseId, AddrProvinciaId: $addrProvinciaId, AddrProvincia: $addrProvincia, AddrComuneId: $addrComuneId, AddrComune: $addrComune, AddrIndirizzo: $addrIndirizzo, AddrN: $addrN, AddrCap: $addrCap, Addr2PaeseId: $addr2PaeseId, Addr2ProvinciaId: $addr2ProvinciaId, Addr2Provincia: $addr2Provincia, Addr2ComuneId: $addr2ComuneId, Addr2Comune: $addr2Comune, Addr2Indirizzo: $addr2Indirizzo, Addr2N: $addr2N, Addr2Cap: $addr2Cap, IsPep: $isPep, IsNotPepMoreThanYear: $isNotPepMoreThanYear, PepCarica: $pepCarica, PepRuolo:$pepRuolo, VersionN: $versionN, VersionDate: $versionDate, IsDeleted: $isDeleted, GenereId: $genereId, WorkspaceId: $workspaceId, AmlId : $AmlId, Office1Id : $Office1Id, Office2Id : $Office2Id ModifiedOn: "now()"}) {
    returning {
        Id
        ParentId
        IsPg
        Cf
        Nome
        Cognome
        GenereId
        NascitaLuogo
        NascitaData
        Professione
        Denominazione
        PIva
        ReaComune
        ReaN
        AddrPaeseId
        AddrProvinciaId
        AddrProvincia
        AddrComuneId
        AddrComune
        AddrIndirizzo
        AddrN
        AddrCap
        Addr2PaeseId
        Addr2ProvinciaId
        Addr2Provincia
        Addr2ComuneId
        Addr2Comune
        Addr2Indirizzo
        Addr2N
        Addr2Cap
        IsPep
        IsNotPepMoreThanYear
        PepCarica
        PepRuolo
        CreatedOn
        VersionN
        VersionDate
        IsDeleted
        CreatedBy
        ModifiedBy
        ModifiedOn
        WorkspaceId
        AmlId
        Office1Id
        Office2Id
        anagrafica_fascicolos {
          Id
          fascicolo {
            Id
            Name
            Date
          }
          base_tipo_anagrafica {
            Id
            Key
            Value
          }
          TipoPartId
          TipoPartDesc
        }
        anagrafica_identificaziones {
          Id
          DocTipoId
          DocN
          DocRilDa
          DocRilData
          DocScad
          ApNome
          ApCf
          IdentifiedByUser
          CreatedOn
          base_tipo_identificazione {
            Id
            Key
            Value
          }
        }
        anagrafica_bancadatis {
          Id
          Repository
          Infos
          CreatedOn
        }
      }
    }
  }
`;
export const mutation_update_anagrafica_version = `
mutation updateAnagrafica($id: Int!, $tenantId: String!, $aziendaId: Int!, $parentId: Int!, $versionN: Int!) {
  update_anagrafica(where: {Id: {_eq: $id}, TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}}, _set: {ParentId: $parentId, VersionN: $versionN}) {
    affected_rows
  }
}
`;
// export const mutation_delete_anagrafica = `
// mutation deleteAnagrafica($id: Int!) {
//   delete_anagrafica(where: {Id: {_eq: $id}}) {
//       affected_rows
//     }
//   }
// `;
export const mutation_delete_anagrafica = `
mutation updateAnagrafica($id: Int!, $fascicoloId: Int!) {
  update_anagrafica(where: {Id: {_eq: $id}, FascicoloId: {_eq: $fascicoloId}}, _set: {IsDeleted: true}) {
      affected_rows
    }
  }
`;
export const mutation_delete_anagrafica_version = `
mutation deleteAnagraficaVersion($id: Int!, $versionN: Int!) {
  delete_anagrafica(where: {Id: {_eq: $id}, VersionN: {_eq: $versionN}}) {
      affected_rows
    }
  }
`;
export const mutation_add_anagrafica_fascicolo = `
mutation addAnagraficaFascicolo($anagraficaId: Int!, $fascicoloId: Int!, $tipoAnagId: Int!, $tipoPartId: Int, $tipoPartDesc: String, $dataFine: timestamp, $tipoRappId: Int, $tipoRappDesc: String) {
  insert_anagrafica_fascicolo_one(object: {AnagraficaId: $anagraficaId, FascicoloId: $fascicoloId, TipoAnagId: $tipoAnagId, TipoPartId: $tipoPartId, TipoPartDesc: $tipoPartDesc, DataFine: $dataFine, TipoRappId:$tipoRappId, TipoRappDesc:$tipoRappDesc }) {
      Id
      AnagraficaId
      FascicoloId
      TipoAnagId
      TipoPartId
      TipoPartDesc
      DataFine
      TipoRappId
      TipoRappDesc
    }
  }
`;
export const mutation_update_anagrafica_fascicolo = `
mutation updateAnagraficaFascicolo($id: Int!, $anagraficaId: Int!, $fascicoloId: Int!, $tipoAnagId: Int!, $tipoPartId: Int, $tipoPartDesc: String, $dataFine: timestamp, $tipoRappId: Int, $tipoRappDesc: String) {
  update_anagrafica_fascicolo(where: {Id: {_eq: $id}, FascicoloId: {_eq: $fascicoloId}}, _set: {AnagraficaId : $anagraficaId, TipoAnagId: $tipoAnagId, TipoPartId: $tipoPartId, TipoPartDesc: $tipoPartDesc, DataFine: $dataFine, TipoRappId:$tipoRappId, TipoRappDesc:$tipoRappDesc}) {
    returning {
      Id
      AnagraficaId
      FascicoloId
      TipoAnagId
      TipoPartId
      TipoPartDesc
      DataFine
      TipoRappId
      TipoRappDesc
      }
    }
  }
`;
export const mutation_delete_anagrafica_fascicolo = `
mutation deleteAnagraficaFascicolo($anagraficaId: Int!, $fascicoloId: Int!, $tipoAnagId: Int!) {
  delete_anagrafica_fascicolo(where: {AnagraficaId: {_eq: $anagraficaId}, FascicoloId: {_eq: $fascicoloId}, TipoAnagId: {_eq: $tipoAnagId}}) {
      affected_rows
    }
  }
`;

/******************************************************/
//Fascicolo - Anagrafiche - Banca dati
/******************************************************/
export const mutation_add_anagrafica_bancadati = `
mutation addAnagraficaBancaDati($anagraficaId: Int!, $repository: String!, $infos: String!) {
  insert_anagrafica_bancadati_one(object: {AnagraficaId: $anagraficaId, Repository: $repository, Infos: $infos}) {
      Id
      Repository
      Infos
      CreatedOn
      CreatedBy
    }
  }
`;
export const mutation_delete_anagrafica_bancadati = `
mutation deleteAnagraficaBancaDati($id: Int!) {
  delete_anagrafica_bancadati(where: {Id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

/******************************************************/
//Fascicolo - Anagrafiche - Identificazione
/******************************************************/
export const mutation_add_anagrafica_ident = `
mutation addAnagraficaIdent($anagraficaId: Int!, $modalitaIdentId: Int!, $docTipoId: Int, $docN: String, $docRilDa: String, $docRilData: timestamp, $docScad: timestamp, $apNome: String, $apCf: String, $identifiedByUser: String!, $identifiedOn: timestamp!) {
  insert_anagrafica_identificazione_one(object: {AnagraficaId: $anagraficaId, ModalitaIdentId: $modalitaIdentId, DocTipoId: $docTipoId, DocN: $docN, DocRilDa: $docRilDa, DocRilData: $docRilData, DocScad: $docScad, ApNome: $apNome, ApCf: $apCf, IdentifiedByUser: $identifiedByUser, IdentifiedOn: $identifiedOn}) {
      Id  
      DocTipoId
      DocN
      DocRilDa
      DocRilData
      DocScad
      ApNome
      ApCf
      IdentifiedByUser
      IdentifiedOn
      CreatedOn
      ModifiedOn
      CreatedBy
      ModifiedBy
      base_tipo_identificazione {
        Id
        Key
        Value
      }
    }
  }
`;
export const mutation_update_anagrafica_ident = `
mutation updateAnagraficaIdent($id: Int!, $anagraficaId: Int!, $modalitaIdentId: Int!, $docTipoId: Int, $docN: String, $docRilDa: String, $docRilData: timestamp, $docScad: timestamp, $apNome: String, $apCf: String, $identifiedByUser: String!, $identifiedOn: timestamp!) {
  update_anagrafica_identificazione(where: {Id: {_eq: $id}, AnagraficaId: {_eq: $anagraficaId}}, _set: {ModalitaIdentId: $modalitaIdentId, DocTipoId: $docTipoId, DocN: $docN, DocRilDa: $docRilDa, DocRilData: $docRilData, DocScad: $docScad, ApNome: $apNome, ApCf: $apCf, IdentifiedByUser: $identifiedByUser, IdentifiedOn: $identifiedOn, ModifiedOn: "now()"}) {
    returning {
        Id
        DocTipoId
        DocN
        DocRilDa
        DocRilData
        DocScad
        ApNome
        ApCf
        IdentifiedByUser
        IdentifiedOn
        CreatedOn
        ModifiedOn
        CreatedBy
        ModifiedBy
        base_tipo_identificazione {
          Id
          Key
          Value
        }
      }
    }
  }
`;
export const mutation_update_anagrafica_ident_data = `
mutation updateAnagraficaIdentData($anagraficaId: Int!, $identifiedOn: timestamp!) {
  update_anagrafica_identificazione(where: {AnagraficaId: {_eq: $anagraficaId}}, _set: {IdentifiedOn: $identifiedOn}) {
    affected_rows
  }
}
`;
export const mutation_delete_anagrafica_ident = `
mutation deleteAnagraficaIdent($id: Int!) {
  delete_anagrafica_identificazione(where: {Id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

/******************************************************/
//Fascicolo - Prestazioni
/******************************************************/
export const mutation_add_prestazione = `
mutation addPrestazione($fascicoloId: Int!, $tipoKey: String!, $prestazioneId: Int!, $naturaId: Int!, $scopoId: Int!, $startDate: timestamp!, $endDate: timestamp!, $paeseId: Int!, $provinciaId: Int, $provincia: String!, $cittaId: Int, $citta: String!, $descrizione: String!, $astensioneMotivo: String, $isEsoneroObbAst: Boolean, $isPrivate: Boolean!) {
  insert_prestazione_one(object: {FascicoloId: $fascicoloId, TipoKey: $tipoKey, PrestazioneId: $prestazioneId, NaturaId: $naturaId, ScopoId: $scopoId, StartDate: $startDate, EndDate: $endDate, PaeseId: $paeseId, ProvinciaId: $provinciaId, Provincia: $provincia, CittaId: $cittaId, Citta: $citta, Descrizione: $descrizione, AstensioneMotivo: $astensioneMotivo, IsEsoneroObbAst: $isEsoneroObbAst, IsPrivate: $isPrivate}) {
      Id
      TipoKey
      PrestazioneId
      StartDate
      EndDate
      PaeseId
      ProvinciaId
      Provincia
      CittaId
      Citta
      ScopoId
      NaturaId
      Descrizione
      AstensioneMotivo
      IsEsoneroObbAst
      IsPrivate
      CreatedOn
      ModifiedOn
      CreatedBy
      ModifiedBy
      base_tipo_prestazione {
        BaseTenantTipoId
        Nome
        Regole
        Rischio
      }
      prestazione_users {
        Id
        PrestazioneId
        UserId
      }
      rischios(order_by: {Date: desc}) {
        Id
        Date
        DataControlloCostante
        ProvidedBy        
      }
    }
  }
`;
export const mutation_update_prestazione = `
mutation updatePrestazione($id: Int!, $tipoKey: String!, $prestazioneId: Int!, $naturaId: Int!, $scopoId: Int!, $startDate: timestamp!, $endDate: timestamp!, $paeseId: Int!, $provinciaId: Int, $provincia: String!, $cittaId: Int, $citta: String!, $descrizione: String!, $astensioneMotivo: String, $isEsoneroObbAst: Boolean, $isPrivate: Boolean!) {
  update_prestazione(where: {Id: {_eq: $id}}, _set: {TipoKey: $tipoKey, PrestazioneId: $prestazioneId, NaturaId: $naturaId, ScopoId: $scopoId, StartDate: $startDate, EndDate: $endDate, PaeseId: $paeseId, ProvinciaId: $provinciaId, Provincia: $provincia, CittaId: $cittaId, Citta: $citta, Descrizione: $descrizione, AstensioneMotivo: $astensioneMotivo, IsEsoneroObbAst: $isEsoneroObbAst, IsPrivate: $isPrivate, ModifiedOn: "now()"}) {
      returning {
        Id
        TipoKey
        PrestazioneId
        StartDate
        EndDate
        PaeseId
        ProvinciaId
        Provincia
        CittaId
        Citta
        ScopoId
        NaturaId
        Descrizione
        AstensioneMotivo
        IsEsoneroObbAst
        IsPrivate
        CreatedOn
        ModifiedOn
        CreatedBy
        ModifiedBy
        base_tipo_prestazione {
          BaseTenantTipoId
          Nome
          Regole
          Rischio
        }
        prestazione_users {
          Id
          PrestazioneId
          UserId
        }
        prestazione_pagamentos {
          Id
          ValutaId
          ValutaData
          ValutaCambio
          ValutaImporto
          ImportoEuro
          ModalitaPagamentoId
          OrigineId
          CreatedOn
          ModifiedOn
          CreatedBy
          ModifiedBy
          base_valutum {
            IsoCode
          }
          base_mezzo_pagamento {
            Value
          }
          base_origine_fondi {
            Value
          }
        }
        rischios(order_by: {Date: desc}) {
          Id
          Date
          DataControlloCostante
          ProvidedBy
        }
      }
    }
  }
`;
export const mutation_delete_prestazione = `
mutation deletePrestazione($id: Int!) {
  delete_prestazione(where: {Id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

/******************************************************/
//Fascicolo - Prestazioni - Utenti
/******************************************************/
export const mutation_add_prestazione_user = `
mutation addPrestazioneUser($prestazioneId: Int!, $userId: String!) {
  insert_prestazione_user_one(object: {PrestazioneId: $prestazioneId, UserId: $userId}) {
      PrestazioneId
      UserId
    }
  }
`;
export const mutation_add_prestazione_users = `
mutation addPrestazioneUsers($objects: [prestazione_user_insert_input!]!) {
  insert_prestazione_user(objects: $objects) {
      affected_rows
    }
  }
`;
export const mutation_delete_prestazione_user = `
mutation deletePrestazioneUser($prestazioneId: Int!) {
  delete_prestazione_user(where: {PrestazioneId: {_eq: $prestazioneId}}) {
      affected_rows
    }
  }
`;

/******************************************************/
//Fascicolo - Prestazioni - Mezzi di pagamento
/******************************************************/
export const mutation_add_prestazione_pagamento = `
mutation addPrestazionePagamento($prestazioneId: Int!, $valutaId: Int!, $valutaData: timestamp!, $valutaCambio: float8!, $valutaImporto: numeric!, $modalitaPagamentoId: Int!, $origineId: Int!, $importoEuro: numeric!, $data: timestamp!) {
  insert_prestazione_pagamento_one(object: {PrestazioneId: $prestazioneId, ValutaId: $valutaId, ValutaData: $valutaData, ValutaCambio: $valutaCambio, ValutaImporto: $valutaImporto, ModalitaPagamentoId: $modalitaPagamentoId, OrigineId: $origineId, ImportoEuro: $importoEuro, Data: $data}) {
    Id
    PrestazioneId
    ValutaId
    ValutaData
    ValutaCambio
    ValutaImporto
    ImportoEuro
    ModalitaPagamentoId
    OrigineId
    CreatedOn
    ModifiedOn
    Data
    CreatedBy
    ModifiedBy
    base_valutum {
      IsoCode
    }
    base_mezzo_pagamento {
      Value
    }
    base_origine_fondi {
      Value
    }
    }
  }
`;
export const mutation_update_prestazione_pagamento = `
mutation updatePrestazionePagamento($id: Int!, $valutaId: Int!, $valutaData: timestamp!, $valutaCambio: float8!, $valutaImporto: numeric!, $modalitaPagamentoId: Int!, $origineId: Int!, $importoEuro: numeric!, $data: timestamp!) {
  update_prestazione_pagamento(where: {Id: {_eq: $id}}, _set: {ValutaId: $valutaId, ValutaData: $valutaData, ValutaCambio: $valutaCambio, ValutaImporto: $valutaImporto, ModalitaPagamentoId: $modalitaPagamentoId, OrigineId: $origineId, ImportoEuro: $importoEuro, Data: $data, ModifiedOn: "now()"}) {
      returning {
        Id
        PrestazioneId
        ValutaId
        ValutaData
        ValutaCambio
        ValutaImporto
        ImportoEuro
        ModalitaPagamentoId
        OrigineId
        CreatedOn
        ModifiedOn
        Data
        CreatedBy
        ModifiedBy
        base_valutum {
          IsoCode
        }
        base_mezzo_pagamento {
          Value
        }
        base_origine_fondi {
          Value
        }        
      }
    }
  }
`;
export const mutation_delete_prestazione_pagamento = `
mutation deletePrestazionePagamento($id: Int!) {
  delete_prestazione_pagamento(where: {Id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

/******************************************************/
//Fascicolo - Valutazioni rischio
/******************************************************/
export const mutation_add_rischio = `
mutation addRischio($prestazioneId: Int!, $date: timestamp!, $spA1Livello: Int!, $spA1_1: Boolean!, $spA1_2: Boolean!, $spA1_3: Boolean!, $spA1_4: Boolean!, $spA1_5: Boolean!, $spA1Altro: Boolean!, $spA1AltroNote: String!, $spA2Altro: Boolean!, $spA2AltroNote: String!, $spA2Livello: Int!, $spA2_1: Boolean!, $spA2_2: Boolean!, $spA2_3: Boolean!, $spA3Altro: Boolean!, $spA3AltroNote: String!, $spA3Livello: Int!, $spA3_1: Boolean!, $spA3_2: Boolean!, $spA3_3: Boolean!, $spA3_4: Boolean!, $spA4Altro: Boolean!, $spA4AltroNote: String!, $spA4Livello: Int!, $spA4_1: Boolean!, $spA4_2: Boolean!, $spASubTotale: numeric!, $spB1Livello: Int!, $spB1_1: Boolean!, $spB1_2: Boolean!, $spB1_3: Boolean!, $spB1Altro: Boolean!, $spB1AltroNote: String!, $spB2Livello: Int!, $spB2_1: Boolean!, $spB2_2: Boolean!, $spB2_3: Boolean!, $spB2_4: Boolean!, $spB2Altro: Boolean!, $spB2AltroNote: String!, $spB3Livello: Int!, $spB3_1: Boolean!, $spB3_2: Boolean!, $spB3Altro: Boolean!, $spB3AltroNote: String!, $spB4Livello: Int!, $spB4_1: Boolean!, $spB4_2: Boolean!, $spB4Altro: Boolean!, $spB4AltroNote: String!, $spB5Livello: Int!, $spB5_1: Boolean!, $spB5_2: Boolean!, $spB5_3: Boolean!, $spB5Altro: Boolean!, $spB5AltroNote: String!, $spB6Livello: Int!, $spB6_1: Boolean!, $spB6_2: Boolean!, $spB6_3: Boolean!, $spB6Altro: Boolean!, $spB6AltroNote: String!, $spBSubTotale: numeric!, $spC1_1_Livello: Int!, $spC1_2_Livello: Int!, $spC1_3_Livello: Int!, $spCSubTotale: numeric!, $efRiTotale: numeric!, $efSpTotale: numeric!, $efTotale: numeric!, $efAdeguataLivello: Int!, $providedBy: String!, $hasTabellaB: Boolean!, $dataControlloCostante: timestamp!) {
  insert_rischio_one(object: {PrestazioneId: $prestazioneId, Date: $date, SpA1Livello: $spA1Livello, SpA1_1: $spA1_1, SpA1_2: $spA1_2, SpA1_3: $spA1_3, SpA1_4: $spA1_4, SpA1_5: $spA1_5, SpA1Altro: $spA1Altro, SpA1AltroNote: $spA1AltroNote, SpA2Altro: $spA2Altro, SpA2AltroNote: $spA2AltroNote, SpA2Livello: $spA2Livello, SpA2_1: $spA2_1, SpA2_2: $spA2_2, SpA2_3: $spA2_3, SpA3Altro: $spA3Altro, SpA3AltroNote: $spA3AltroNote, SpA3Livello: $spA3Livello, SpA3_1: $spA3_1, SpA3_2: $spA3_2, SpA3_3: $spA3_3, SpA3_4: $spA3_4, SpA4Altro: $spA4Altro, SpA4AltroNote: $spA4AltroNote, SpA4Livello: $spA4Livello, SpA4_1: $spA4_1, SpA4_2: $spA4_2, SpASubTotale: $spASubTotale, SpB1Livello: $spB1Livello, SpB1_1: $spB1_1, SpB1_2: $spB1_2, SpB1_3: $spB1_3, SpB1Altro: $spB1Altro, SpB1AltroNote: $spB1AltroNote, SpB2Livello: $spB2Livello, SpB2_1: $spB2_1, SpB2_2: $spB2_2, SpB2_3: $spB2_3, SpB2_4: $spB2_4, SpB2Altro: $spB2Altro, SpB2AltroNote: $spB2AltroNote, SpB3Livello: $spB3Livello, SpB3_1: $spB3_1, SpB3_2: $spB3_2, SpB3Altro: $spB3Altro, SpB3AltroNote: $spB3AltroNote, SpB4Livello: $spB4Livello, SpB4_1: $spB4_1, SpB4_2: $spB4_2, SpB4Altro: $spB4Altro, SpB4AltroNote: $spB4AltroNote, SpB5Livello: $spB5Livello, SpB5_1: $spB5_1, SpB5_2: $spB5_2, SpB5_3: $spB5_3, SpB5Altro: $spB5Altro, SpB5AltroNote: $spB5AltroNote, SpB6Livello: $spB6Livello, SpB6_1: $spB6_1, SpB6_2: $spB6_2, SpB6_3: $spB6_3, SpB6Altro: $spB6Altro, SpB6AltroNote: $spB6AltroNote, SpBSubTotale: $spBSubTotale, SpC1_1_Livello: $spC1_1_Livello, SpC1_2_Livello: $spC1_2_Livello, SpC1_3_Livello: $spC1_3_Livello, SpCSubTotale: $spCSubTotale, EfRiTotale: $efRiTotale, EfSpTotale: $efSpTotale, EfTotale: $efTotale, EfAdeguataLivello: $efAdeguataLivello, ProvidedBy: $providedBy, HasTabellaB: $hasTabellaB, DataControlloCostante: $dataControlloCostante}) {
    Id
    PrestazioneId
    Date
    SpA1Livello
    SpA1_1
    SpA1_2
    SpA1_3
    SpA1_4
    SpA1_5
    SpA1Altro
    SpA1AltroNote
    SpA2Livello
    SpA2_1
    SpA2_2
    SpA2_3
    SpA2Altro
    SpA2AltroNote
    SpA3Livello
    SpA3_1
    SpA3_2
    SpA3_3
    SpA3_4
    SpA3Altro
    SpA3AltroNote
    SpA4Livello
    SpA4_1
    SpA4_2
    SpA4Altro
    SpA4AltroNote
    SpASubTotale
    SpB1Livello
    SpB1_1
    SpB1_2
    SpB1_3
    SpB1Altro
    SpB1AltroNote
    SpB2Livello
    SpB2_1
    SpB2_2
    SpB2_3
    SpB2_4
    SpB2Altro
    SpB2AltroNote
    SpB3Livello
    SpB3_1
    SpB3_2
    SpB3Altro
    SpB3AltroNote
    SpB4Livello
    SpB4_1
    SpB4_2
    SpB4Altro
    SpB4AltroNote
    SpB5Livello
    SpB5_1
    SpB5_2
    SpB5_3
    SpB5Altro
    SpB5AltroNote
    SpB6Livello
    SpB6_1
    SpB6_2
    SpB6_3
    SpB6Altro
    SpB6AltroNote
    SpBSubTotale
    SpC1_1_Livello
    SpC1_2_Livello
    SpC1_3_Livello
    SpCSubTotale
    EfRiTotale
    EfSpTotale
    EfTotale
    EfAdeguataLivello
    ProvidedBy
    CreatedOn
    ModifiedOn
    HasTabellaB
    DataControlloCostante
    CreatedBy
    ModifiedBy
    }
  }
`;
export const mutation_update_rischio = `
mutation updateRischio($id: Int!, $prestazioneId: Int!, $date: timestamp!, $spA1Livello: Int!, $spA1_1: Boolean!, $spA1_2: Boolean!, $spA1_3: Boolean!, $spA1_4: Boolean!, $spA1_5: Boolean!, $spA1Altro: Boolean!, $spA1AltroNote: String!, $spA2Altro: Boolean!, $spA2AltroNote: String!, $spA2Livello: Int!, $spA2_1: Boolean!, $spA2_2: Boolean!, $spA2_3: Boolean!, $spA3Altro: Boolean!, $spA3AltroNote: String!, $spA3Livello: Int!, $spA3_1: Boolean!, $spA3_2: Boolean!, $spA3_3: Boolean!, $spA3_4: Boolean!, $spA4Altro: Boolean!, $spA4AltroNote: String!, $spA4Livello: Int!, $spA4_1: Boolean!, $spA4_2: Boolean!, $spASubTotale: numeric!, $spB1Livello: Int!, $spB1_1: Boolean!, $spB1_2: Boolean!, $spB1_3: Boolean!, $spB1Altro: Boolean!, $spB1AltroNote: String!, $spB2Livello: Int!, $spB2_1: Boolean!, $spB2_2: Boolean!, $spB2_3: Boolean!, $spB2_4: Boolean!, $spB2Altro: Boolean!, $spB2AltroNote: String!, $spB3Livello: Int!, $spB3_1: Boolean!, $spB3_2: Boolean!, $spB3Altro: Boolean!, $spB3AltroNote: String!, $spB4Livello: Int!, $spB4_1: Boolean!, $spB4_2: Boolean!, $spB4Altro: Boolean!, $spB4AltroNote: String!, $spB5Livello: Int!, $spB5_1: Boolean!, $spB5_2: Boolean!, $spB5_3: Boolean!, $spB5Altro: Boolean!, $spB5AltroNote: String!, $spB6Livello: Int!, $spB6_1: Boolean!, $spB6_2: Boolean!, $spB6_3: Boolean!, $spB6Altro: Boolean!, $spB6AltroNote: String!, $spBSubTotale: numeric!, $spC1_1_Livello: Int!, $spC1_2_Livello: Int!, $spC1_3_Livello: Int!, $spCSubTotale: numeric!, $efRiTotale: numeric!, $efSpTotale: numeric!, $efTotale: numeric!, $efAdeguataLivello: Int!, $providedBy: String!, $hasTabellaB: Boolean!, $dataControlloCostante: timestamp!) {
  update_rischio(where: {Id: {_eq: $id}}, _set: {PrestazioneId: $prestazioneId, Date: $date, SpA1Livello: $spA1Livello, SpA1_1: $spA1_1, SpA1_2: $spA1_2, SpA1_3: $spA1_3, SpA1_4: $spA1_4, SpA1_5: $spA1_5, SpA1Altro: $spA1Altro, SpA1AltroNote: $spA1AltroNote, SpA2Altro: $spA2Altro, SpA2AltroNote: $spA2AltroNote, SpA2Livello: $spA2Livello, SpA2_1: $spA2_1, SpA2_2: $spA2_2, SpA2_3: $spA2_3, SpA3Altro: $spA3Altro, SpA3AltroNote: $spA3AltroNote, SpA3Livello: $spA3Livello, SpA3_1: $spA3_1, SpA3_2: $spA3_2, SpA3_3: $spA3_3, SpA3_4: $spA3_4, SpA4Altro: $spA4Altro, SpA4AltroNote: $spA4AltroNote, SpA4Livello: $spA4Livello, SpA4_1: $spA4_1, SpA4_2: $spA4_2, SpASubTotale: $spASubTotale, SpB1Livello: $spB1Livello, SpB1_1: $spB1_1, SpB1_2: $spB1_2, SpB1_3: $spB1_3, SpB1Altro: $spB1Altro, SpB1AltroNote: $spB1AltroNote, SpB2Livello: $spB2Livello, SpB2_1: $spB2_1, SpB2_2: $spB2_2, SpB2_3: $spB2_3, SpB2_4: $spB2_4, SpB2Altro: $spB2Altro, SpB2AltroNote: $spB2AltroNote, SpB3Livello: $spB3Livello, SpB3_1: $spB3_1, SpB3_2: $spB3_2, SpB3Altro: $spB3Altro, SpB3AltroNote: $spB3AltroNote, SpB4Livello: $spB4Livello, SpB4_1: $spB4_1, SpB4_2: $spB4_2, SpB4Altro: $spB4Altro, SpB4AltroNote: $spB4AltroNote, SpB5Livello: $spB5Livello, SpB5_1: $spB5_1, SpB5_2: $spB5_2, SpB5_3: $spB5_3, SpB5Altro: $spB5Altro, SpB5AltroNote: $spB5AltroNote, SpB6Livello: $spB6Livello, SpB6_1: $spB6_1, SpB6_2: $spB6_2, SpB6_3: $spB6_3, SpB6Altro: $spB6Altro, SpB6AltroNote: $spB6AltroNote, SpBSubTotale: $spBSubTotale, SpC1_1_Livello: $spC1_1_Livello, SpC1_2_Livello: $spC1_2_Livello, SpC1_3_Livello: $spC1_3_Livello, SpCSubTotale: $spCSubTotale, EfRiTotale: $efRiTotale, EfSpTotale: $efSpTotale, EfTotale: $efTotale, EfAdeguataLivello: $efAdeguataLivello, ProvidedBy: $providedBy, HasTabellaB: $hasTabellaB, DataControlloCostante: $dataControlloCostante, ModifiedOn: "now()"}) {
    returning {
        Id
        PrestazioneId
        Date
        SpA1Livello
        SpA1_1
        SpA1_2
        SpA1_3
        SpA1_4
        SpA1_5
        SpA1Altro
        SpA1AltroNote
        SpA2Livello
        SpA2_1
        SpA2_2
        SpA2_3
        SpA2Altro
        SpA2AltroNote
        SpA3Livello
        SpA3_1
        SpA3_2
        SpA3_3
        SpA3_4
        SpA3Altro
        SpA3AltroNote
        SpA4Livello
        SpA4_1
        SpA4_2
        SpA4Altro
        SpA4AltroNote
        SpASubTotale
        SpB1Livello
        SpB1_1
        SpB1_2
        SpB1_3
        SpB1Altro
        SpB1AltroNote
        SpB2Livello
        SpB2_1
        SpB2_2
        SpB2_3
        SpB2_4
        SpB2Altro
        SpB2AltroNote
        SpB3Livello
        SpB3_1
        SpB3_2
        SpB3Altro
        SpB3AltroNote
        SpB4Livello
        SpB4_1
        SpB4_2
        SpB4Altro
        SpB4AltroNote
        SpB5Livello
        SpB5_1
        SpB5_2
        SpB5_3
        SpB5Altro
        SpB5AltroNote
        SpB6Livello
        SpB6_1
        SpB6_2
        SpB6_3
        SpB6Altro
        SpB6AltroNote
        SpBSubTotale
        SpC1_1_Livello
        SpC1_2_Livello
        SpC1_3_Livello
        SpCSubTotale
        EfRiTotale
        EfSpTotale
        EfTotale
        EfAdeguataLivello
        ProvidedBy
        CreatedOn
        ModifiedOn
        HasTabellaB
        DataControlloCostante
        CreatedBy
        ModifiedBy
      }
    }
  }
`;
export const mutation_delete_rischio = `
mutation deleteRischio($id: Int!) {
  delete_rischio(where: {Id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

/******************************************************/
//Fascicolo - Indicatori anomalia
/******************************************************/
export const mutation_add_indicatore_anomalia = `
mutation addIndicatoreAnomalia($fascicoloId: Int!, $date: timestamp!, $providedBy: String!, $result: String!, $note: String) {
  insert_indicatore_anomalia_one(object: {FascicoloId: $fascicoloId, Date: $date, ProvidedBy: $providedBy, Result: $result, Note: $note }) {
      Id
      Date
      ProvidedBy
      Result
      Note
      CreatedOn
      ModifiedOn
      CreatedBy
      ModifiedBy
    }
  }
`;
export const mutation_update_indicatore_anomalia = `
mutation updateIndicatoreAnomalia($id: Int!, $fascicoloId: Int!, $date: timestamp!, $providedBy: String!, $result: String!, $note: String) {
  update_indicatore_anomalia(where: {Id: {_eq: $id}, FascicoloId: {_eq: $fascicoloId}}, _set: {Date: $date, ProvidedBy: $providedBy, Result: $result, Note: $note, ModifiedOn: "now()"}) {
    returning {
        Id
        Date
        ProvidedBy
        Result
        Note
        CreatedOn
        ModifiedOn
        CreatedBy
        ModifiedBy
      }
    }
  }
`;
export const mutation_delete_indicatore_anomalia = `
mutation deleteIndicatoreAnomalia($id: Int!, $fascicoloId: Int!) {
  delete_indicatore_anomalia(where: {Id: {_eq: $id}, FascicoloId: {_eq: $fascicoloId}}) {
      affected_rows
    }
  }
`;

/******************************************************/
//SOS
/******************************************************/
export const mutation_add_sos = `
mutation addSos($fascicoloId: Int!, $date: timestamp!, $motivo: String!, $note: String!, $receiptId: String!, $reportedByUser: String!) {
  insert_sos_one(object: {FascicoloId: $fascicoloId, Date: $date, Motivo: $motivo, Note: $note, ReceiptId: $receiptId, ReportedByUser: $reportedByUser}) {
      Id
      Date
      Motivo
      Note
      ReceiptId
      ReportedByUser
      CreatedOn
      ModifiedOn
      CreatedBy
      ModifiedBy
    }
  }
`;
export const mutation_update_sos = `
mutation updateSos($id: Int!, $date: timestamp!, $motivo: String!, $note: String!, $receiptId: String!, $reportedByUser: String!) {
  update_sos(where: {Id: {_eq: $id}}, _set: {Date: $date, Motivo: $motivo, Note: $note, ReceiptId: $receiptId, ReportedByUser: $reportedByUser, ModifiedOn: "now()"}) {
      returning {
        Id
        Date
        Motivo
        Note
        ReceiptId
        ReportedByUser
        CreatedOn
        ModifiedOn
        CreatedBy
        ModifiedBy
      }
    }
  }
`;
export const mutation_delete_sos = `
mutation deleteSos($id: Int!) {
  delete_sos(where: {Id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

/******************************************************/
//Autovalutazioni rischio
/******************************************************/
export const mutation_add_autovalutazione = `
mutation addAutovalutazione($tenantId: String!, $aziendaId: Int!, $tenantTipoId: Int!, $date: timestamp!, $ri1: Int!, $ri2: Int!, $ri3: Int!, $ri4: Int!, $lv1: Int!, $lv2: Int!, $lv3: Int!, $lv4: Int!, $riTotale: numeric!, $lvTotale: numeric!, $rrTotale: numeric!, $livelloRischio: Int!, $providedBy: String!, $note: String!) {
  insert_autovalutazione_one(object: {TenantId: $tenantId, AziendaId: $aziendaId, TenantTipoId: $tenantTipoId, Date: $date, Ri1: $ri1, Ri2: $ri2, Ri3: $ri3, Ri4: $ri4, Lv1: $lv1, Lv2: $lv2, Lv3: $lv3, Lv4: $lv4, RiTotale: $riTotale, LvTotale: $lvTotale, RrTotale: $rrTotale, LivelloRischio: $livelloRischio, ProvidedBy: $providedBy, Note: $note}) {
    Id
    TenantId
    AziendaId
    TenantTipoId
    Date
    Ri1
    Ri2
    Ri3
    Ri4
    Lv1
    Lv2
    Lv3
    Lv4
    RiTotale
    LvTotale
    RrTotale
    LivelloRischio
    ProvidedBy
    Note
    CreatedOn
    ModifiedOn
    CreatedBy
    ModifiedBy
    }
  }
`;
export const mutation_update_autovalutazione = `
mutation updateAutovalutazione($id: Int!, $tenantId: String!, $aziendaId: Int!, $tenantTipoId: Int!, $date: timestamp!, $ri1: Int!, $ri2: Int!, $ri3: Int!, $ri4: Int!, $lv1: Int!, $lv2: Int!, $lv3: Int!, $lv4: Int!, $riTotale: numeric!, $lvTotale: numeric!, $rrTotale: numeric!, $livelloRischio: Int!, $providedBy: String!, $note: String!) {
  update_autovalutazione(where: {Id: {_eq: $id}, TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}}, _set: {TenantTipoId: $tenantTipoId, Date: $date, Ri1: $ri1, Ri2: $ri2, Ri3: $ri3, Ri4: $ri4, Lv1: $lv1, Lv2: $lv2, Lv3: $lv3, Lv4: $lv4, RiTotale: $riTotale, LvTotale: $lvTotale, RrTotale: $rrTotale, LivelloRischio: $livelloRischio, ProvidedBy: $providedBy, Note: $note, ModifiedOn: "now()"}) {
    returning {
        Id
        TenantId
        AziendaId
        TenantTipoId
        Date
        Ri1
        Ri2
        Ri3
        Ri4
        Lv1
        Lv2
        Lv3
        Lv4
        RiTotale
        LvTotale
        RrTotale
        LivelloRischio
        ProvidedBy
        Note
        CreatedOn
        ModifiedOn
        CreatedBy
        ModifiedBy
      }
    }
  }
`;
export const mutation_delete_autovalutazione = `
mutation deleteAutovalutazione($id: Int!) {
  delete_autovalutazione(where: {Id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

/******************************************************/
//Allegati
/******************************************************/
export const mutation_add_allegato = `
mutation addAllegato($tenantId: String!, $entityType: String!, $entityId: Int!, $fileName: String!, $fileType: String!, $fileUrl: String!, $displayedName: String!) {
  insert_allegato_one(object: {TenantId: $tenantId, EntityType: $entityType, EntityId: $entityId, FileName: $fileName, FileType: $fileType, FileUrl: $fileUrl, DisplayedName: $displayedName}) {
    Id
    EntityType
    EntityId
    FileName
    FileType
    FileUrl
    DisplayedName
    CreatedOn
    ModifiedOn
    }
  }
`;
export const mutation_upload_allegato_toStorage = `
mutation uploadAttachment($data: String!) {
  uploadAttachment(data: $data) {
    path, fileName
  }
}
`;
export const mutation_delete_allegato = `
mutation deleteAllegato($id: Int!) {
  delete_allegato(where: {Id: {_eq: $id}}) {
      affected_rows
    }
  }
`;
export const mutation_delete_allegato_fromStorage = `
mutation deleteAttachment($fileName: String!) {
  deleteAttachment(fileName: $fileName) {
    value
  }
}
`;

/******************************************************/
//Impostazioni
/******************************************************/
//Audit
export const mutation_add_audit = `
mutation addAudit($tenantId: String!, $aziendaId: Int!, $area: String!, $operation: String!, $entityType: String!, $entityId: String!, $oldValues: String!, $newValues: String!) {
    insert_audit_one(object: {TenantId: $tenantId, AziendaId: $aziendaId, Area: $area, Operation: $operation, EntityType: $entityType, EntityId: $entityId, OldValues: $oldValues, NewValues: $newValues}) {
        Id
      }
  }
`;

//Permessi
export const mutation_add_user_permission = `
mutation addUserPermission($userId: String!, $aziendaId: Int!, $area: String!, $canRead: Boolean!, $canCreate: Boolean!, $canUpdate: Boolean!, $canDelete: Boolean!) {
    insert_user_permission_one(object: {UserId: $userId, AziendaId: $aziendaId, Area: $area, CanRead: $canRead, CanCreate: $canCreate, CanUpdate: $canUpdate, CanDelete: $canDelete}) {
        Id
        AziendaId
        Area
        CanRead
        CanCreate
        CanUpdate
        CanDelete
      }
  }
`;
export const mutation_update_user_permission = `
mutation updateUserPermission($id: Int!, $userId: String!, $aziendaId: Int!, $canRead: Boolean!, $canCreate: Boolean!, $canUpdate: Boolean!, $canDelete: Boolean!) {
    update_user_permission(where: {Id: {_eq: $id}, UserId: {_eq: $userId}, AziendaId: {_eq: $aziendaId}}, _set: {CanRead: $canRead, CanCreate: $canCreate, CanUpdate: $canUpdate, CanDelete: $canDelete}) {
      returning {
        Id
        AziendaId
        Area
        CanRead
        CanCreate
        CanUpdate
        CanDelete
      }
    }
  }
`;
export const mutation_update_user_permission_allRead = `
mutation updateUserPermissionAllRead($userId: String!, $aziendaId: Int!, $canRead: Boolean!) {
    update_user_permission(where: {UserId: {_eq: $userId}, AziendaId: {_eq: $aziendaId}}, _set: {CanRead: $canRead}) {
      returning {
        Id
        AziendaId
        Area
        CanRead
        CanCreate
        CanUpdate
        CanDelete
      }
    }
  }
`;
export const mutation_update_user_permission_allCreate = `
mutation updateUserPermissionAllCreate($userId: String!, $aziendaId: Int!, $canCreate: Boolean!) {
    update_user_permission(where: {UserId: {_eq: $userId}, AziendaId: {_eq: $aziendaId}}, _set: {CanCreate: $canCreate}) {
      returning {
        Id
        AziendaId
        Area
        CanRead
        CanCreate
        CanUpdate
        CanDelete
      }
    }
  }
`;
export const mutation_update_user_permission_allUpdate = `
mutation updateUserPermissionAllUpdate($userId: String!, $aziendaId: Int!, $canUpdate: Boolean!) {
    update_user_permission(where: {UserId: {_eq: $userId}, AziendaId: {_eq: $aziendaId}}, _set: {CanUpdate: $canUpdate}) {
      returning {
        Id
        AziendaId
        Area
        CanRead
        CanCreate
        CanUpdate
        CanDelete
      }
    }
  }
`;
export const mutation_update_user_permission_allDelete = `
mutation updateUserPermissionAllDelete($userId: String!, $aziendaId: Int!, $canDelete: Boolean!) {
    update_user_permission(where: {UserId: {_eq: $userId}, AziendaId: {_eq: $aziendaId}}, _set: {CanDelete: $canDelete}) {
      returning {
        Id
        AziendaId
        Area
        CanRead
        CanCreate
        CanUpdate
        CanDelete
      }
    }
  }
`;

//User
export const mutation_add_user = `
mutation addUser($userId: String!, $username: String!, $email: String!, $name: String!, $surname: String!, $locale: String!, $isRespAntiRic: Boolean!, $isActive: Boolean!, $isDeleted: Boolean!, $isBanned: Boolean!, $createdOn: timestamptz, $cf: String, $lastVersion : String) {
    insert_user_one(object: { UserId: $userId, Username: $username, Email: $email, Name: $name, Surname: $surname, Locale: $locale, IsRespAntiRic: $isRespAntiRic, IsActive: $isActive, IsDeleted: $isDeleted, IsBanned: $isBanned, CreatedOn: $createdOn, Cf: $cf, LastVersion : $lastVersion }) {
      UserId
      Username
      Email
      Name
      Surname
      Locale
      IsRespAntiRic
      IsActive
      IsDeleted
      IsBanned
      CreatedOn
      Cf
      LastVersion
      user_tenants {
        TenantId
        Type
        TenantTipoId
        base_tipo_tenant {
          Value
        }
      }
    }
  }
`;
export const mutation_update_user_resetRespAntiRic = `
mutation resetRespAntiRic($tenantId: String!) {
  update_user(where: {
    user_tenants: {TenantId: {_eq: $tenantId}}}, 
    _set: {IsRespAntiRic: false}) {
    affected_rows
    }
  }
`;
export const mutation_update_user = `
mutation updateUser($tenantId: String!, $userId: String!, $name: String!, $surname: String!, $isRespAntiRic: Boolean!, $isActive: Boolean!, $isDeleted: Boolean!, $isBanned: Boolean!, $createdOn: timestamptz, $cf: String, $lastVersion : String) {
    update_user(where: {
      user_tenants:{TenantId: {_eq: $tenantId}, UserId: {_eq: $userId}}},
      _set: {
        Name: $name, Surname: $surname, IsRespAntiRic: $isRespAntiRic, IsActive: $isActive, IsDeleted: $isDeleted, IsBanned: $isBanned, CreatedOn: $createdOn, Cf: $cf, LastVersion : $lastVersion
      }) {
      returning {
        UserId
        Username
        Email
        Name
        Surname
        Locale
        IsRespAntiRic
        IsActive
        IsDeleted
        IsBanned
        CreatedOn
        Cf
        LastVersion
        user_tenants {
          TenantId
          Type
          TenantTipoId
          base_tipo_tenant {
            Value
          }
        }
      }
    }
  }
`;

//User Tenant
export const mutation_add_user_tenant = `
mutation addUserTenant($tenantId: String!, $userId: String!, $type: String!, $tenantTipoId: Int!) {
  insert_user_tenant_one(object: { UserId: $userId, TenantId: $tenantId, Type: $type, TenantTipoId: $tenantTipoId}, on_conflict: {constraint: user_tenant_pkey, update_columns: Id}) {
      Id
      UserId
      TenantId
      Type
      TenantTipoId
      base_tipo_tenant {
        Value
      }
    }
  }
`;
export const mutation_update_user_tenant = `
mutation updateUserTenant($tenantId: String!, $userId: String!, $tenantTipoId: Int!) {
    update_user_tenant(where: {
      TenantId: {_eq: $tenantId}, UserId: {_eq: $userId}
    },
      _set: {
        TenantTipoId: $tenantTipoId
      }) {
      returning {
        Id
        UserId
        TenantId
        Type
        TenantTipoId
        base_tipo_tenant {
          Value
        }
      }
    }
  }
`;

/******************************************************/
//Notification
/******************************************************/
export const mutation_update_notification = `
mutation updateNotification($id: Int!, $tenantId: String!, $aziendaId: Int!, $isRead: Boolean!, $readOn: timestamptz!) {
    update_notification(where: {Id: {_eq: $id}, TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}}, _set: {IsRead: $isRead, ReadOn: $readOn}) {
      returning {
        Id
        TenantId
        AziendaId
        UserId
        Type
        Payload
        IsRead
        ReadOn
        ReadByUser
        CreatedOn
      }
    }
  }
`;

/******************************************************/
//Last activity
/******************************************************/
export const mutation_delete_lastActivity = `
mutation deleteLastActivity($tenantId: String!, $aziendaId: Int!, $userId: String!) {
  delete_user_lastactivity(where: {TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}, UserId: {_eq: $userId}}) {
        affected_rows
    }
}
`;
export const mutation_add_lastActivity_byUserId = `
mutation addLastActivity($tenantId: String!, $aziendaId: Int!, $path: String!, $entityId: String, $entityName: String) {
  insert_user_lastactivity_one(object: { TenantId: $tenantId, AziendaId: $aziendaId, Path: $path, EntityId: $entityId, EntityName: $entityName}) {
    Id
  }
}
`;

/******************************************************/
//Log
/******************************************************/
export const mutation_add_log = `
mutation addLog($tenantId: String, $aziendaId: Int, $level: String!, $message: String!, $stack: String, $area: String, $method: String, $version: String) {
  insert_log_one(object: {TenantId: $tenantId, AziendaId: $aziendaId, Level: $level, Message: $message, Stack: $stack, Area: $area, Method: $method, Version: $version}) {
    Id
  }
}
`;

/******************************************************/
//Import Mapping
/******************************************************/
export const mutation_add_import_mapping = `
mutation addLog($tenantId: String, $aziendaId: Int, $type: String, $mappedValue: String!, $value: String!) {
  insert_import_mapping_one(object: {TenantId: $tenantId, AziendaId: $aziendaId, Type: $type, MappedValue: $mappedValue, Value: $value}) {
    Id
    TenantId
    AziendaId
    Type
    MappedValue
    Value
    CreatedOn
    ModifiedOn
    CreatedBy
    ModifiedBy
  }
}
`;
export const mutation_update_import_mapping = `
mutation updateImportMapping($tenantId: String!, $aziendaId: Int, $type: String, $mappedValue: String!, $value: String!) {
  update_import_mapping(where: {TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}, Type: {_eq: $type}, MappedValue: {_eq: $mappedValue}}, _set: {Value: $value, ModifiedOn: "now()"}) {
    returning {
      Id
      TenantId
      AziendaId
      Type
      MappedValue
      Value
      CreatedOn
      ModifiedOn
      CreatedBy
      ModifiedBy
    }
  }
}
`;

/******************************************************/
//Metering
/******************************************************/
export const mutation_add_metering_consumption = `
mutation addMeteringConsumption($tenantId: String!, $companyName: String!, $companyId: Int!) {
  addMeteringConsumption(tenantId: $tenantId, companyName: $companyName, companyId: $companyId) {
    value
  }
}
`;
export const mutation_read_metering_consumption = `
mutation readMeteringConsumption($tenantId: String!) {
  readMeteringConsumption(tenantId: $tenantId) {
    packageId
    availableSlots
    usedSlots
    lockedSlots
    coolDownSlots
    remainingSlots
  }
}
`;

/******************************************************/
//JSReport
/******************************************************/
export const mutation_get_document = `
mutation getJsReportDocument($data: String!) {
  getJsReportDocument(data: $data) {
    value
  }
}
`;
export const mutation_get_document_and_save = `
mutation getJsReportDocumentAndSave($data: String!) {
  getJsReportDocumentAndSave(data: $data) {
    fileName
    fileType
    fileUrl
  }
}
`;

/******************************************************/
//BancheDati
/******************************************************/
export const mutation_search_legal_entity = `
mutation searchLegalEntity($identifier: String!, $companyName: String!){
  searchLegalEntity(identifier: $identifier, companyName: $companyName){
    data
  }
}`

export const mutation_search_natural_person = `
mutation searchNaturalPerson($bornDate: String!, $name: String!, $surname: String!){
  searchNaturalPerson(bornDate: $bornDate, name: $name, surname:$surname){
    data
  }
}`